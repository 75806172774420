export const DFPBannerData = {
  en: [
    {
      id: 'dfp-banner-1',
      href: 'https://mdza.io/u16pVu9ab1A',
      image:
        'https://meduza.io/image/attachments/images/010/639/252/original/DNYO4E_hWaon0rpRlCPaHg.png'
    },
    {
      id: 'dfp-banner-2',
      href: 'https://mdza.io/6rKV0EXTX24',
      image:
        'https://meduza.io/image/attachments/images/010/639/244/original/fyS387VS_3HC7LfMnzZbbg.png'
    }
  ],

  ru: [
    {
      id: 'dfp-ru-banner-1',
      href:
        'https://support.meduza.io/?utm_source=meduza&utm_medium=banner_aside&utm_campaign=ny2025',
      image:
        'https://meduza.io/image/attachments/images/010/738/970/original/hvIqkaE4j9uWaL7gradXhw'
    }
  ]
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { PopupBannerContainer } from '@client/components/Common/PopupBanner/PopupBannerContainer'
import { POPUP_BANNER_MATERIAL_TYPES } from '@client/constants'
import { MaterialContent, RootState } from '@client/types'
import { Skeleton } from '@common/Skeleton'
import { Layout } from '@components/Layout/Layout'
import { Meta } from '@components/Layout/Meta'
import { useBridge } from '@hooks/useBridge'
import { usePageViewAnalytics } from '@hooks/usePageViewAnalytics'
import { getApp } from '@selectors/appSelectors'
import { getLang } from '@selectors/currentUserSelectors'
import { getMaterial } from '@selectors/materialsSelectors'
import { hideFootnote, showFootnote } from '@store/Footnote/footnoteActions'
import {
  fetchMaterial,
  fetchUnblockLink
} from '@store/Materials/materialsActions'
import { showModal } from '@store/Modal/modalActions'
import { fetchUnderTheSun } from '@store/Screens/screensActions'
import { getSelectedText } from '@utils/getSelectedText'
import equal from 'fast-deep-equal/es6'

import { Affiliate } from './MaterialComponents/Affiliate'
import { TopBar } from './MaterialComponents/TopBar'
import { UnderTheSun } from './MaterialComponents/UnderTheSun'
import { renderMaterialComponents } from './renderMaterialComponents'

import styles from './Material.module.css'

interface FootnoteElement extends HTMLElement {
  dataset: {
    id: string
    highlight: string
    body: string
    title: string
  }
}

interface MaterialParams {
  lang?: string
  material?: string
  year?: string
  month?: string
  day?: string
  slug?: string
}

export const Material: React.FC<RouteConfigComponentProps<MaterialParams>> = ({
  location: { pathname },
  route,
  match: {
    params: { lang: paramsLang, material: materialName, year, month, day, slug }
  }
}) => {
  useBridge()
  const dispatch = useDispatch()
  const containerRoot = useRef<HTMLDivElement>(null)
  const materialRoot = useRef<HTMLDivElement>(null)

  const { isEmbedded, isInApp } = useSelector(getApp)

  const url = [paramsLang, materialName, year, month, day, slug]
    .filter((part) => !!part)
    .join('/')

  const isBrand = materialName == 'brand'

  const lang = useSelector(getLang)

  const materialContent: MaterialContent = useSelector(
    (state: RootState) => getMaterial(state, url),
    equal
  )

  const material = materialContent

  const hideUnderTheSun =
    material && (!material.under_the_sun || !material.under_the_sun.show)

  usePageViewAnalytics({
    pathname,
    title: material && material.og.title,
    tag: material && material.tag && material.tag.name
  })

  const [showUnderTheSun, setShowUnderTheSun] = useState(true)
  const [showAffiliate, setShowAffiliate] = useState(true)

  const unblockPatch = material?.og?.unblock?.standard.path

  useEffect(() => {
    if (unblockPatch) {
      dispatch(
        fetchUnblockLink.request({
          url: unblockPatch
        })
      )
    }
    dispatch(fetchMaterial.request(url))
    dispatch(fetchUnderTheSun.request(lang))
    setShowUnderTheSun(!(isEmbedded || isInApp || isBrand || hideUnderTheSun))
    setShowAffiliate(!isBrand)
    window.scrollTo({ top: 0 })
  }, [
    dispatch,
    url,
    lang,
    unblockPatch,
    isEmbedded,
    isInApp,
    isBrand,
    hideUnderTheSun
  ])

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement

      const activeFootnote: HTMLElement | null = window.document.querySelector(
        '[data-highlight="true"]'
      )

      if (activeFootnote) {
        activeFootnote.dataset.highlight = 'false'
        dispatch(hideFootnote())
      }

      if (target.classList && target.classList.contains('FootnoteLink')) {
        const footnoteLink = event.target as FootnoteElement

        if (activeFootnote?.dataset?.id === footnoteLink.dataset.id) {
          return
        }

        const targetRect = footnoteLink.getBoundingClientRect()
        const rect = {
          top:
            targetRect.top +
            (document.body.scrollTop || document.documentElement.scrollTop) -
            8,
          left: targetRect.right
        }

        dispatch(
          showFootnote({
            id: footnoteLink.dataset.id,
            title: footnoteLink.dataset.title,
            body: footnoteLink.dataset.body,
            originRect: rect,
            containerRect: containerRoot.current.getBoundingClientRect()
          })
        )
      }
    },
    [dispatch]
  )

  const handleCtrlEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
        const selection = getSelectedText()

        if (selection) {
          dispatch(
            showModal({
              type: 'textError',
              props: {
                ...selection
              }
            })
          )
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    window.addEventListener('click', handleClick)
    window.addEventListener('keydown', handleCtrlEnter)

    return () => {
      window.removeEventListener('click', handleClick)
      window.removeEventListener('keydown', handleCtrlEnter)
    }
  }, [handleClick, handleCtrlEnter])

  const shouldRenderPopupBanner = false

  return (
    <>
      <Layout
        pathname={pathname}
        routeName={route && route.name}
        type="material"
        materialName={materialName}
      >
        {!material ? (
          <Skeleton styleContext="isInMaterial" />
        ) : (
          <div className={styles.root} ref={containerRoot}>
            {showAffiliate && material.affiliate && (
              <Affiliate
                logo={material.affiliate.image_url}
                link={material.affiliate.sponsored_url}
              />
            )}
            <Meta material={material} lang={lang} />
            <div className={styles.container} ref={materialRoot}>
              {!isBrand && (
                <TopBar
                  material={material}
                  lang={lang}
                  showContent={true}
                  containerRef={materialRoot}
                />
              )}
              {renderMaterialComponents(material)}
            </div>
            {showAffiliate && material.affiliate && (
              <Affiliate
                logo={material.affiliate.image_url}
                link={material.affiliate.sponsored_url}
              />
            )}
            {shouldRenderPopupBanner && (
              <PopupBannerContainer
                key={url}
                material={material}
                parentRef={materialRoot}
              />
            )}
            {showUnderTheSun && <UnderTheSun material={material} lang={lang} />}
          </div>
        )}
      </Layout>
    </>
  )
}

Material.whyDidYouRender = true

import { Lang, Newsletter } from '@client/types'
import { ENV_PUBLIC } from '@client/utils/envPublic'
import { getApiRoutes } from '@client/utils/getApiRoutes'
import getBaseUrl from '@utils/getBaseUrl'
import axios from 'axios'
import {
  FetchChronoPayload,
  FetchScreenPayload,
  FetchSearchPayload,
  FetchSpecialPayload,
  NotificationSubscriptionPayload
} from 'types/storeTypes'

// TODO: можно сделать decamelize

const apiClient = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: getBaseUrl()
})

const apiRoutes = getApiRoutes()

// TODO: вынести роуты в отдельный файл
const getStatUrl = (key: string) => `/stat/put${key}?client=website`
const getScreenUrl = (name: string, lang?: string) =>
  `api/w5${lang === 'en' ? '/en' : ''}/screens/${name}`
const getSpecialUrl = (slug: string) => `api/w5/screens/specials/${slug}`
const getChronoUrl = (screenName: string, page: number, lang: string) =>
  `api/w5/${apiRoutes.search}?chrono=${screenName}&page=${page}&per_page=24&locale=${lang}`
const getSearchUrl = (query: string, page: number, lang: string) =>
  `/api/w5/${apiRoutes.search}?term=${query}&page=${page}&per_page=24&locale=${lang}`

export function* sendStat(key: string) {
  return yield apiClient.get(getStatUrl(key))
}

export function* fetchScreen({ name, lang }: FetchScreenPayload): unknown {
  return yield apiClient.get(getScreenUrl(name, lang))
}

export function* fetchSpecials({ slug }: FetchSpecialPayload): unknown {
  return yield apiClient.get(getSpecialUrl(slug))
}

export function* fetchExchangeRates(): unknown {
  return yield apiClient.get(`api/misc/stock/all`)
}

export function* fetchBanners(): unknown {
  return yield apiClient.get(`api/w5/banners`)
}

export function* fetchPopoverBanner(): unknown {
  return yield apiClient.get(`api/w5/popup`)
}

export function* subscribeToDaily(
  params: URLSearchParams,
  lang: Newsletter
): unknown {
  return yield apiClient.post(`/digests/${lang}/captcha`, params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function* getFeedbackToken(): unknown {
  return yield apiClient.get('/embed/feedback/meduza/token', {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

export function* sendTextError(data: {
  comment: string
  key: string
  before: string
  after: string
  error: string
}): unknown {
  return yield apiClient.post('/api/spelling', data, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

export function* sendReaction(data: {
  csrf: string
  payload: {
    key: string
    email: string
    text: string
    tag: string
    type: string
  }
}): unknown {
  return yield apiClient.post('/embed/feedback/meduza/reaction', data)
}

export function* fetchChrono({
  screenName,
  lang,
  page = 0
}: FetchChronoPayload): unknown {
  return yield apiClient.get(getChronoUrl(screenName, page, lang))
}

export function* fetchSearch({
  query,
  lang,
  page = 0
}: FetchSearchPayload): unknown {
  return yield apiClient.get(getSearchUrl(query, page, lang))
}

export function* fetchBookmarks(keys: string[]): unknown {
  return yield apiClient.post(`api/w5/${apiRoutes.search}`, {
    bookmarks: 'load',
    keys
  })
}

export function* callToPushNotifications({
  token,
  topic,
  action
}: NotificationSubscriptionPayload): unknown {
  return yield apiClient.post('/fcm', {
    token,
    topic,
    action
  })
}

export function* fetchMaterial(url: string): unknown {
  return yield apiClient.get(`api/w5/${url}`)
}

export function* fetchTopbarAds(lang: Lang): unknown {
  return yield apiClient.get(
    `api/w5/${lang === 'en' ? 'topbarad_en' : 'topbarad'}`
  )
}

export function* fetchUnblockUrl(url: string): unknown {
  return yield apiClient.get(url)
}

export function* fetchUnderTheSun(lang: Lang): unknown {
  return yield apiClient.get(
    `api/w5/screens/specials/${
      lang === 'en' ? 'under-the-sun-eng' : 'under-the-sun'
    }`
  )
}
